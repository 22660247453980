import StatCard from "./StatCard";
import {Grid} from "@mui/material";

export default function Account({
                                    netLiquidation,
                                    unrealized,
                                    percentGain
                                }) {

    const percentFormatter = Intl.NumberFormat('en-US', {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return (
        <div className="account">
            <Grid container spacing={2}>
                <Grid item md={4} >
                    <StatCard name="Net Liquidation"
                              value={netLiquidation}
                    />
                </Grid>
                <Grid item md={4} >
                    <StatCard name="Unrealized"
                              value={unrealized}
                    />
                </Grid>
                <Grid item md={4} >
                    <StatCard name="Percent Gain"
                              value={percentGain}
                              formatter={percentFormatter}
                    />
                </Grid>
            </Grid>

        </div>
    );
}