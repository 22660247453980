import Card from '@mui/material/Card';
import {Typography} from "@mui/material";
import styles from "./styles/Dashboard.module.css"

export default function StatCard({ name = "",
                                 value = 0,
                                 formatter = null}) {
    const statCardStyle = {
        color: 'black',
        width: 300
    };

    if (formatter === null) {
        formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    return (
        <Card sx={statCardStyle}>
            <Typography gutterBottom variant="body" component="div">
                <center>{name}</center>
            </Typography>
            <Typography variant="h5" color="#85bb65">
                <center>{formatter.format(value.toFixed(2))}</center>
            </Typography>
        </Card>
    );
}