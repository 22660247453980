import "@aws-amplify/ui-react/styles.css";
import {
    withAuthenticator,
    Button,
    View,
} from "@aws-amplify/ui-react";
import {Amplify, API, Auth} from 'aws-amplify';
import awsconfig from './aws-exports';
import {useState, useEffect} from 'react'
import Account from './Account'
import {Sidebar, Menu, MenuItem, SubMenu, useProSidebar} from "react-pro-sidebar";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PasswordIcon from '@mui/icons-material/Password';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ReceiptIcon from '@mui/icons-material/Receipt';

Amplify.configure(awsconfig);

function App({signOut}) {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
        useProSidebar();

    const toggle = () => {
        toggleSidebar();
        if (toggled) {
            console.log(true);
            collapseSidebar();
        } else {
            console.log(false);
            collapseSidebar();
        }
    };

    const [accounts, setAccounts] = useState([{
        accountId: 'Loading...',
        netLiquidation: 'Loading...',
        unrealized: 'Loading...',
        percentGain: 'Loading...'
    }]);

    useEffect(() => {
        async function getData() {
            const apiName = 'restapi';
            const path = '/dashboard';

            const userInfo = await Auth.currentUserInfo();
            const response = await API.post(apiName, path, {
                'queryStringParameters': {
                    'username': userInfo.attributes.email
                }
            });

            const accountValues = [];

            for (let i = 0; i < response.data.length; i += 1) {
                accountValues.push(response.data);
            }
            setAccounts(response.data.accountDataList);
            return JSON.stringify(response.data);
        }

        getData().then((data) => {
            console.log(data);
        });
    }, []);

    return (
        <div id="app" style={({height: "100vh"}, {display: "flex"})}>
            <Sidebar
                backgroundColor="rgb(0, 249, 249)"
                style={{height: "100vh"}}>
                <Menu>
                    <MenuItem
                        icon={<MenuOutlinedIcon/>}
                        onClick={() => {
                            collapseSidebar();
                        }}
                        style={{textAlign: "center"}}
                    >
                        {" "}
                        <h2>Admin</h2>
                    </MenuItem>
                    <MenuItem icon={<DashboardIcon/>}>Dashboard</MenuItem>
                    <SubMenu icon={<ManageAccountsIcon />} label="Account">
                        <MenuItem icon={<TrendingUpIcon />}>Algorithms</MenuItem>
                        <MenuItem icon={<ReceiptIcon />}>Billing</MenuItem>
                        <MenuItem icon={<PasswordIcon />}>Password</MenuItem>
                    </SubMenu>
                    <MenuItem icon={<HelpOutlineOutlinedIcon/>}>FAQ</MenuItem>
                </Menu>
            </Sidebar>
            <main>
                <h1 style={{ color: "white", marginLeft: "5rem" }}>
                    Hindsight
                </h1>
                <View className="App">
                    <div className="App">
                    </div>
                    <div>
                        {accounts.map((account, index) =>
                            <Account
                                key={index}
                                accountId={account.accountId}
                                netLiquidation={account.netLiquidation}
                                unrealized={account.unrealized}
                                percentGain={account.percentGain}

                            />
                        )}
                    </div>
                    <Button onClick={signOut}>Sign opopo</Button>
                </View>
            </main>
        </div>
    );
}

export default withAuthenticator(App);