import { useState, useEffect } from "react";
import BalancedAlgorithmStats from "./BalanceAlgorithmStats";
import BalancedAlgorithmDescription from "./BalancedAlgorithmDescription";
import BalancedAlgorithmSettings from "./BalancedAlgorithmSettings";
import { API } from "aws-amplify";

export default function BalancedAlgorithm() {
    const [data, setData] = useState({});

    /*useEffect(() => {
        async function getData() {
            const apiName = 'restapi';
            const path = '/dashboard';

            const response = await API.post(apiName, path, {
                'queryStringParameters': {
                    command: `getBalancedAlgorithmData --algorithm balance_account`
                }
            });

            setData(response.data)
            return JSON.stringify(response.data);
        }

        getData().then((data) => {
            console.log(data);
        });
    }, []);*/

    return (
        <div>
            {/*<BalancedAlgorithmStats/>*/}
            <BalancedAlgorithmDescription />
            <BalancedAlgorithmSettings
            broker={"alpaca"}/>
        </div>
    )
}