import React from "react";
import Chart from "react-apexcharts";

export default function DepositAndWithdrawalsChart (chartData) {

    const depositSeriesData = [];
    const withdrawalsSeriesData = [];

    const state = {
        options: {
            chart: {
                id: "depositAndWithdrawalChart"
            },
            title: {
                text: "Deposits and Withdrawals"
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: 'datetime'
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        });
                        return formatter.format(value)
                    }
                }
            },
            colors: ['#ae1000', '#50c878'],
            noData: {
                text: "No Data",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                }
            }
        },
        series: chartData.data.xyDateSeries
    };

    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={state.options}
                        series={state.series}
                        type={"scatter"}
                        height={300}
                    />
                </div>
            </div>
        </div>
    );
}