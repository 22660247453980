import React from "react";
import Chart from "react-apexcharts";

export default function PositionsPieChart (chartData) {

    const state = {
        options: {
            chart: {
                height: 50
            },
            labels: chartData.data.categories,
            title: {
                text: "Account Allocation"
            },
            tooltip: {
                y: {
                    formatter: function (value) {
                        const formatter = Intl.NumberFormat('en-US', {
                            style: "percent",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                        });
                        return formatter.format(value)
                    }
                }
            },
            noData: {
                text: "No Positions",
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: undefined,
                    fontSize: '14px',
                    fontFamily: undefined
                }
            },
        },
        series: chartData.data.categoryValues
    }

    return (
        <div className="app">
            <div className="mixed-chart">
                <Chart options={state.options}
                       series={state.series}
                       type="pie"
                       height={300}/>
            </div>
        </div>
    );
}