import {FormControl, MenuItem, Select, TextField} from "@mui/material";
import {Button} from "@aws-amplify/ui-react";
import { useState} from "react";
import {Grid} from "@mui/material";
export default function BalancedAlgorithmSettings({
                                                      broker = "Test",
                                                      brokerAccountNumber = "Test",
                                                      cost = 10,
                                                  }) {

    const [credentials, setCredentials] = useState("");
    const [addAccountBroker, setAddAccountBroker] = useState("");
    const [showCreateAccountSelection, setShowCreateAccountSelection] = useState(false);

    const handleUpdateCredentialClick = () => {
        setCredentials(broker);
    }

    const handleCreateAccountClick = () => {
        setShowCreateAccountSelection(true);
    }
    const handleCreateAccountSelection = (event) => {
        const broker = event.target.value;
        setAddAccountBroker(broker);
    }

    const accountCreateDropDown = (
        <div>
            <FormControl fullWidth>
            <Select
                onChange={handleCreateAccountSelection}
                >
                <MenuItem key="alpaca-select" value="alpaca">Alpaca</MenuItem>
                <MenuItem value={"ib"}>Interactive Brokers</MenuItem>
                <MenuItem value={"tradier"}>Tradier</MenuItem>
            </Select>
            </FormControl>
        </div>
    )

    const ibAccountDetails = (
        <div>
            <div>
                <TextField variant="outlined"
                           label="Username"/>
                <TextField variant="outlined"
                           label="Password"
                           type="password"/>
            </div>
            <div>
                <TextField variant="outlined"
                           label="Account Number"/>
            </div>
        </div>

    )

    const ibCredentials = (
        <div>
            <TextField variant="outlined"
                       label="username"/>
            <TextField variant="outlined"
                       label="password"
                       type="password"/>
        </div>
    )

    const alpacaCredentials = (
        <div>
            <TextField variant="outlined"
                       label="API Key"/>
            <TextField variant="outlined"
                       label="API Secret"
                       type="password"/>
        </div>
    )

    const tradierCredentials = (
        <div>
            <TextField variant="outlined"
                       label="API Key"/>
        </div>
    )

    let currentCredentials;
    if (credentials === 'ib') {
        currentCredentials = ibCredentials;
    }
    else if (credentials === 'alpaca') {
        currentCredentials = alpacaCredentials;
    }
    else if (credentials == 'tradier') {
        currentCredentials = tradierCredentials;
    }
    else {
        currentCredentials = (
            <div></div>
        );
    }

    let showAccountCreateDropDown;
    if (showCreateAccountSelection) {
        showAccountCreateDropDown = accountCreateDropDown;
    }
    else {
        showAccountCreateDropDown = (
            <div></div>
        )
    }

   /* let currentAccountDetails;
    if (broker === 'ib') {
        currentAccountDetails = ibAccountDetails;
    }
    else if (broker === 'alpaca') {
        currentAccountDetails = alpacaCredentials;
    }
    else if (broker === 'tradier') {
        currentAccountDetails = tradierCredentials;
    }
    else {
        currentAccountDetails = (
            <div></div>
        );
    }*/

    let showAccountCreation;
    if (addAccountBroker === 'ib') {
        showAccountCreation = ibAccountDetails;
    }
    else if (addAccountBroker === 'alpaca') {
        showAccountCreation = alpacaCredentials;
    }
    else if (addAccountBroker === 'tradier') {
        showAccountCreation = tradierCredentials;
    }
    else {
        showAccountCreation = (
            <div></div>
        );
    }

    return (
        <div>
            <h1>My Algorithms</h1>
            <Grid container spacing={2}>
                <Grid item md={6} >
                    <label>Cost: ${cost}</label>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} >
                    <label>Broker: {broker}</label>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} >
                    <label>Broker Account #: {brokerAccountNumber}</label>
                    {currentCredentials}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} >
                    <Button onClick={handleUpdateCredentialClick}>Update Credentials</Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} >
                    <Button >Pause</Button>
                    <Button >Cancel</Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={6} >
                    <Button onClick={handleCreateAccountClick}>Add Account</Button>
                    {showAccountCreateDropDown}
                    {showAccountCreation}
                </Grid>
            </Grid>
        </div>
    )
}
