import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ProSidebarProvider} from "react-pro-sidebar";
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from './routes/root';
import ErrorPage from "./error-page";
import Dashboard from "./Dashboard";
import FAQ from "./FAQ";
import Algorithms from "./Algorithms";
import Billing from "./Billing";
import Password from "./Password";
import BalancedAlgorithm from "./pages/balanced-algorithm/BalancedAlgorithm";

Amplify.configure(config);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "dashboard",
                element: <Dashboard />
            },
            {
                path: "faq",
                element: <FAQ />
            },
            {
                path: "algorithms",
                element: <Algorithms
                algorithm={{name: "testAlgo"}}/>
            },
            {
                path: "balancedAlgorithm",
                element: <BalancedAlgorithm />
            },
            {
                path: "billing",
                element: <Billing />
            },
            {
                path: "password",
                element: <Password />
            }
        ]
    },
    ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ProSidebarProvider>
        <RouterProvider router={router} />
        </ProSidebarProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
