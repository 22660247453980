import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {v4 as uuidv4} from "uuid";

function createData(
    ticker,
    date,
    action,
    quantity,
    price,
) {
    return { ticker, date, action, quantity, price };
}

export default function ExecutionTable(executions) {
    let rows = []

    for (let i = 0; i < executions.executions.length; i += 1) {
        const currentExecution = executions.executions[i];
        rows.push(createData(currentExecution.ticker,
            currentExecution.executionDate,
            currentExecution.side,
            currentExecution.quantity,
            currentExecution.executionPrice
            ));
    }

    return (
        <div>
            <h4>Execution Details</h4>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                <Table sx={{ minWidth: 100 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ticker</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Action</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={uuidv4()}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.ticker}
                                </TableCell>
                                <TableCell align="right">{row.date}</TableCell>
                                <TableCell align="right">{row.action}</TableCell>
                                <TableCell align="right">{`$${row.quantity.toFixed(2)}`}</TableCell>
                                <TableCell align="right">{`$${row.price.toFixed(2)}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}