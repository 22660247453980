import React from "react";
import Chart from "react-apexcharts";

export default function GrowthOverTimeChart (chartData) {
        const state = {
            options: {
                chart: {
                    height: 100
                },
                title: {
                    text: "Growth Over Time"
                },
                xaxis: {
                    type: 'datetime'
                },
                stroke: {
                    curve: 'smooth'
                },
                dataLabels: {
                    enabled: false
                },
                yaxis: {
                    labels: {
                        formatter: (value) => {
                            const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });
                            return formatter.format(value)
                        }
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            });
                            return formatter.format(value)
                        }
                    }
                },
                colors: ['#ffb100']
            },
            series: chartData.data.xyDateSeries
        };

    return (
        <div className="app">
            <div className="row">
                <div className="mixed-chart">
                    <Chart
                        options={state.options}
                        series={state.series}
                        type="line"
                        height={300}
                    />
                </div>
            </div>
        </div>
    );
}
