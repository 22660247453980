import {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {Button, View} from "@aws-amplify/ui-react";
import Account from "./Account";
import GrowthOverTimeChart from "./GrowthOverTimeChart";
import PositionsTable from "./PositionsTable"
import ExecutionsTable from "./ExecutionsTable"
import {Box, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import PositionsPieChart from "./PositionsPieChart";
import DepositAndWithdrawalsChart from "./DepositsAndWithdrawlsChart";
import InterestAndDividendChart from "./InterestAndDividendChart";
const { v4: uuidv4 } = require('uuid');

export default function Dashboard() {
    const [currentAccount, setCurrentAccount] = useState({});
    const [accounts, setAccounts] = useState([{
        accountId: 'Loading...',
        netLiquidation: 'Loading...',
        unrealized: 'Loading...',
        percentGain: 'Loading...'
    }]);
    const [accountIds, setAccountIds] = useState([]);
    const [growthData, setGrowthData] = useState({})
    const [positionsData, setPositionsData] = useState({})
    const [interestDividendData, setInterestDividendData] = useState({})
    const [withdrawalsDeposits, setWithdrawalsDeposits] = useState({})
    const [positions, setPositions] = useState([])
    const [executions, setExecutions] = useState([])

    const handleAccountIdChange = (event) => {
        const accountId = event.target.value;

        for (let i = 0; i < accounts.length; i += 1) {
            if (accounts[i].accountId == accountId) {
                setCurrentAccount(accounts[i]);
                setGrowthData(accounts[i].growthChartData);
                setPositionsData(accounts[i].positionsChartData);
                setInterestDividendData(accounts[i].interestDividendChartData);
                setWithdrawalsDeposits(accounts[i].withdrawalsDepositsChartData);
                setPositions(accounts[i].positions)
                setExecutions(accounts[i].executions)
                break;
            }
        }
    }

    useEffect(() => {
        async function getData() {
            const apiName = 'restapi';
            const path = '/dashboard';

            const userInfo = await Auth.currentUserInfo();
            const response = await API.post(apiName, path, {
                'queryStringParameters': {
                    command: `getDashboardData --username ${userInfo.attributes.email}`
                }
            });

            const accountValues = [];
            const accountIds = [];

            for (let i = 0; i < response.data.accountDataList.length; i += 1) {
                accountValues.push(response.data);
                accountIds.push(
                    {
                        value: response.data.accountDataList[i].accountId,
                        label: response.data.accountDataList[i].accountId
                    }
                )
            }

            setAccounts(response.data.accountDataList);
            setAccountIds(Array.from(accountIds));
            setCurrentAccount(response.data.accountDataList[0]);
            setGrowthData(response.data.accountDataList[0].growthChartData)
            setPositionsData(response.data.accountDataList[0].positionsChartData);
            setInterestDividendData(response.data.accountDataList[0].interestDividendChartData);
            setWithdrawalsDeposits(response.data.accountDataList[0].withdrawalsDepositsChartData);
            setPositions(response.data.accountDataList[0].positions)
            setExecutions(response.data.accountDataList[0].executions)
            return JSON.stringify(response.data);
        }

        getData().then((data) => {
            console.log(data);
        });
    }, []);

    // Create the components to hold the title
    let accountSelection;
    if (accountIds) {
        if (accountIds.length === 1) {
            accountSelection = (
                <div>
                </div>
            );
        }
        else if (accountIds.length === 0) {
            accountSelection = (
                <div></div>
            );
        }
        else {
            accountSelection = (
                <div>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl >
                            <Select
                                onChange={handleAccountIdChange}
                                labelId="account-id-select-label"
                                id="account-id-select"
                                defaultValue={currentAccount.accountId}
                                value={currentAccount.accountId}
                            >
                                {accountIds.map(item => {
                                    return (<MenuItem key={uuidv4()} value={item.value}>{item.label}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </div>
            );
        }
    }
    else {
        accountSelection = (
            <div></div>
        )
    }

    // Create the components to hold the account data
    let accountStatsDisplay;
    if (Object.keys(currentAccount).length > 0) {
        accountStatsDisplay = (
            <div>
                <Account
                    netLiquidation={currentAccount.netLiquidation}
                    unrealized={currentAccount.unrealized}
                    percentGain={currentAccount.percentGain}
                />
            </div>
        );
    }
    else {
        accountStatsDisplay = (
            <div></div>
        );
    }

    // Growth chart
    let growthChartDisplay;
    if (growthData !== undefined && Object.keys(growthData).length > 0) {
        growthChartDisplay = (
            <div>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <GrowthOverTimeChart
                            data={growthData}/>
                    </Grid>
                    <Grid item md={4}>
                        <PositionsPieChart
                            data={positionsData}/>
                    </Grid>
                    <Grid item md={4}>
                        <InterestAndDividendChart
                            data={interestDividendData}/>
                    </Grid>
                    <Grid item md={4}>
                        <DepositAndWithdrawalsChart
                            data={withdrawalsDeposits}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
    else {
        growthChartDisplay = (
            <div></div>
        )
    }

    // Positions and Executions
    let positionsAndExecutionsDisplay;
    if (positions.length > 0 || executions.length > 0) {
        positionsAndExecutionsDisplay = (
            <div>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <PositionsTable
                        positions={positions}/>
                    </Grid>
                    <Grid item md={6}>
                        <ExecutionsTable
                        executions={executions}/>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <View className="App">
            {accountSelection}
            {accountStatsDisplay}
            {growthChartDisplay}
            {positionsAndExecutionsDisplay}
        </View>
    );
}