import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const { v4: uuidv4 } = require('uuid');

function createData(
    ticker,
    quantity,
    market,
    cost,
    unrealized,
    buyPrice,
    lastPrice
) {
    return { ticker, quantity, market, cost, unrealized, buyPrice, lastPrice };
}

export default function PositionsTable(positions) {
    let rows = [];

    for (let i = 0; i < positions.positions.length; i += 1) {
        const currentPosition = positions.positions[i];
        rows.push(createData(currentPosition.ticker,
            currentPosition.quantity,
            currentPosition.marketValue,
            currentPosition.costBasis,
            currentPosition.unrealized,
            currentPosition.buyPrice,
            currentPosition.lastPrice));
    }

    return (
        <div>
            <h4>Position Details</h4>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
                <Table sx={{ minWidth: 100 }} stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                            <TableCell>Ticker</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Market</TableCell>
                            <TableCell align="right">Cost</TableCell>
                            <TableCell align="right">Unrealized</TableCell>
                            <TableCell align="right">Buy&nbsp;Price</TableCell>
                            <TableCell align="right">Last&nbsp;Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {rows.map((row) => (
                            <TableRow
                                key={uuidv4()}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.ticker}
                                </TableCell>
                                <TableCell align="right">{row.quantity.toFixed(2)}</TableCell>
                                <TableCell align="right">{`$${row.market.toFixed(2)}`}</TableCell>
                                <TableCell align="right">{`$${row.cost.toFixed(2)}`}</TableCell>
                                <TableCell align="right">{`$${row.unrealized.toFixed(2)}`}</TableCell>
                                <TableCell align="right">{`$${row.buyPrice.toFixed(2)}`}</TableCell>
                                <TableCell align="right">{`$${row.lastPrice.toFixed(2)}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}